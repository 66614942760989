import React from "react";
import { Container, Breadcrumb} from "react-bootstrap";
import {Link} from 'gatsby'
import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Post } from "../../components/Core";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import {Helmet} from "react-helmet";
import {StaticImage} from 'gatsby-plugin-image';

function startabusiness() {
    return (
        <>
        <PageWrapper footerDark>
    	<Helmet>
                <meta charSet="utf-8" />
                <title>Frais de tenue de compte - comment lire entre les lignes de votre banque ?</title>
                <meta name="description" content="
              La gratuité des frais de tenue de compte est partout ! Qu'est-il réellement du coût de ces banques nouvelles génération ?" />
        </Helmet>
      	<Header />
      	<Section>
      	<Container style={{maxWidth: 1280}}>
		  <Breadcrumb id='breadyTop'>
			<Breadcrumb.Item> <Link to={`/`} style={{color: 'black'}}>Accueil</Link></Breadcrumb.Item>
			<Breadcrumb.Item> <Link to={`/academie/`} style={{color: 'black'}}>Banque Académie</Link></Breadcrumb.Item>
			<Breadcrumb.Item active id='bready'>Frais de tenue de compte bancaire – Peut-on les éviter ?</Breadcrumb.Item>
      	 </Breadcrumb>
        	<Title id='mobileTitle'>Frais de tenue de compte bancaire – Peut-on les éviter ?</Title>
			<StaticImage
		  	src='../../images/frais-de-tenue-de-banque.jpeg'
			width={1200}
			alt='Tarifs des banques'
			placeholder='tracedSVG'
			id='pickky'
			/>
			<Post>
			<p>Il fût un temps où les frais de tenue de compte n’étaient pas facturés… Ce temps était celui où la réglementation n’encadrait pas encore certains frais facturés par les banques. Ainsi, ils seraient destinés à financer les moyens humains et informatiques liés à la gestion du compte, qu’en est-il vraiment ?
			</p>
<h2>Quels sont les vraix coûts bancaires ?</h2>
<p>Principalement destinés à financer les moyens humains et informatiques liés à la gestion du compte, les frais de tenue de compte peuvent varier de 0 € à 36 € selon les banques avec une moyenne de 24 €. Un consommateur peut avoir recours à un comparateur de banque pour trouver la moins chère et notamment celle qui ne pratique pas de frais de tenue de compte.
</p><p>
Il convient de préciser qu’un compte inactif, c’est-à-dire sur lequel aucune opération n’a été effectuée par le titulaire ou son représentant légal durant 12 mois et qu’aucun d’eux ne s’est manifesté auprès de la banque, fait l’objet de frais pouvant s’élever jusqu’à 30 €.
</p>
<h2>Les banques qui font sans frais</h2>
<p>Il est bon de savoir que les frais de tenue de compte sont négociables avec son banquier. Il est aussi bon de savoir que certaines banques n’appliquent pas ces frais. C’est particulièrement le cas des banques en ligne ou de certaines banques 100 % mobile. On constate également que les frais de tenue de compte sont parfois offerts jusqu’à 25 ans ou sous certaines conditions comme la domiciliation des revenus ou en respectant les conditions d’utilisation de la carte de paiement.
</p>		</Post>
		</Container>
		</Section>
		<Footer/>
      </PageWrapper>
    </>
    )
}

export default startabusiness
